/** @define footer;  weak */
.footer {
  color: #ffffff;
  background-color: #222;
}
.footer__adress {
  width: 100%;
  padding: 50px 20px;
}
@media only screen and (min-width: 800px) {
  .footer__adress {
    width: 50%;
    text-align: center;
  }
}
@media only screen and (min-width: 1200px) {
  .footer__adress {
    width: 25%;
    padding: 70px 20px;
    text-align: left;
  }
}
.footer__adress .title {
  font-size: 35px;
  line-height: 1.1;
}
.footer__adress .subtitle {
  color: #f7a810;
}
.footer__contact {
  position: relative;
  width: 100%;
  padding: 50px 20px;
  background-color: #f7a810;
}
@media only screen and (min-width: 800px) {
  .footer__contact {
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .footer__contact {
    width: 25%;
    padding: 70px 35px;
  }
}
.footer__contact .wrapper {
  width: 100%;
}
.footer__contact .item {
  position: relative;
  width: 100%;
  padding: 25px 0;
  font-size: 18px;
  text-align: center;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (min-width: 1200px) {
  .footer__contact .item {
    padding: 45px 0;
    text-align: right;
  }
}
.footer__contact .item:after {
  position: absolute;
  bottom: 0;
  width: 170px;
  height: 1px;
  margin-left: -85px;
  background-color: #f9b940;
  content: "";
}
@media only screen and (min-width: 1200px) {
  .footer__contact .item:after {
    right: 0;
    margin-left: 0;
  }
}
.footer__contact .lastitem:after {
  display: none;
}
.footer__contact a {
  color: #ffffff;
}
.footer__contact a:hover {
  color: #70aa40;
}
.footer__contact:after {
  position: absolute;
}
@media only screen and (max-width: 1199px) {
  .footer__contact:after {
    bottom: -17px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -17px;
    border-color: #f7a810 transparent transparent transparent;
    border-style: solid;
    border-width: 17px 17px 0 17px;
    content: "";
  }
}
@media only screen and (min-width: 1200px) {
  .footer__contact:after {
    top: 50%;
    right: -17px;
    width: 0;
    height: 0;
    margin-top: -17px;
    border-color: transparent transparent transparent #f7a810;
    border-style: solid;
    border-width: 17px 0 17px 17px;
    content: "";
  }
}
.footer__contactform {
  width: 100%;
  padding: 50px 20px;
}
@media only screen and (min-width: 1200px) {
  .footer__contactform {
    width: 50%;
    padding: 70px;
  }
}
.footer__social {
  justify-content: center;
}
@media only screen and (min-width: 1200px) {
  .footer__social {
    justify-content: flex-end;
  }
}
.footer__socialink {
  padding: 12px;
}
.footer__socialink .icon_svg {
  width: 34px;
  height: 34px;
  color: #ffffff;
}
.footer__socialink:hover .icon_svg {
  color: #70aa40;
}
.footer__socialink span {
  display: none;
}
.footer__credits {
  padding: 44px 0;
  border-top: 1px solid #443d33;
}
.footer__credits .icon_footerlogo {
  width: 185px;
  height: 48px;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #f7a810;
}
.footer__creator {
  padding: 15px 0;
}
.footer__creator a {
  color: #ffffff;
}
.footer__creator .icon_heart {
  width: 20px;
  height: 20px;
  margin: 0 8px;
  color: #be2929;
}
/*# sourceMappingURL=css/footer.css.map */